<template>
    <div class="rb-view">运动课程设置 (课程模板列表)</div>
</template>

<script>
    export default {
        name: "sport-list"
    }
</script>

<style scoped>

</style>
